import React from "react";

import TitleFragment from "./TitleFragment";

function QtTraining() {
    return (
        <TitleFragment title="Qt Training" />
    )
}

export default QtTraining;