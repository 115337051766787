const VisitorInterviewBranch = [
    {
        id: 1,
        value: "Not Selected",
        key: "Select"
    },
    {
        id: 2,
        value: "Computer Science",
        key: "Computer Science"
    },
    {
        id: 3,
        value: "Information Science",
        key: "Information Science"
    },
    {
        id: 4,
        value: "Electronics and Communication",
        key: "Electronics and Communication"
    },
    {
        id: 5,
        value: "MCA",
        key: "MCA"
    },
    {
        id: 6,
        value: "Others",
        key: "Others"

    }
];

export default VisitorInterviewBranch