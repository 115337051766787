import React from "react";

import TitleFragment from "./TitleFragment";

import pic1 from "./images/aboutus/pexels-fauxels-3183150.jpg";
import pic2 from "./images/aboutus/colleagues-working-project-discussing-details.jpg";
import pic3 from "./images/aboutus/pexels-rdne-7414284.jpg";
import './styles/AboutUsStyles.css';

function AboutUs() {
    return (
        <>
        <TitleFragment title="About Us" />
        <section className="about-section">


            {/* Section 1 */}
            <div className="about-row">
                <div className="about-content">

                    <p><span className="about-us-title"><b>PthinkS Private Limited</b></span> is a premier technology company with a decade-long dedication to C++, <a href="https://www.qt.io/" target="_blank" style={{textDecoration: "none"}}>Qt</a>, and <a href="https://doc.qt.io/qt-6/qmlapplications.html" target="_blank" style={{textDecoration: "none"}}>QML</a> software development and OpenGL implementation. Our commitment to excellence is rooted in principles of innovation, ethics, and discipline.</p>
                    <p>We are a trusted partner for businesses seeking cutting-edge technology solutions. Over the years, we’ve honed our expertise to deliver robust, cross-platform solutions combining performance with seamless user experiences.</p>
                </div>
                <div className="about-image">
                    <img src={pic2} alt="About Us Vision" />
                </div>
            </div>

            {/* Section 2 */}
            <div className="about-row reverse">
                <div className="about-image">
                    <img src={pic1} alt="Our Philosophy" />
                </div>
                <div className="about-content">
                    <h2>Our Philosophy</h2>
                    <p>At PthinkS, we’re passionate about creating a world where technology serves as a force for good. Guided by ethics and discipline, we deliver solutions built on trust and excellence.</p>
                    <p>Choosing PthinkS means choosing a partner who values your success as much as their own. We don’t just deliver software—we deliver solutions with peace of mind.</p>
                </div>
            </div>

            {/* Section 3 */}
            <div className="about-row">
                <div className="about-content">
                    <h2>Our Expertise</h2>
                    <p>PthinkS specializes in high-performance, cross-platform <a href="https://www.qt.io/" target="_blank" style={{textDecoration: "none"}}>Qt</a> and <a href="https://doc.qt.io/qt-6/qmlapplications.html" target="_blank" style={{textDecoration: "none"}}>QML</a> applications that feature visually stunning OpenGL graphics. Our technical expertise enables us to tackle even the most unique and demanding challenges.</p>
                </div>
                <div className="about-image">
                    <img src={pic3} alt="Technical Expertise" />
                </div>
            </div>
        </section>
        </>
    );
}

export default AboutUs;