const MissionData = [
    {
        id: 1,
        text: "Empower businesses with pioneering C++, Qt, and QML software solutions."
    },
    {
        id: 2,
        text: "Uphold excellence and deliver exceptional results with every project."
    },
    {
        id: 3,
        text: "Maintain strong ethical standards and foster transparency in all interactions."
    },
    {
        id: 4,
        text: "Build long-term client partnerships through tailored solutions and support."
    },
    {
        id: 5,
        text: "Integrate sustainability into operations for eco-friendly growth."
    }
]

export default MissionData;