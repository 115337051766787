import React from "react";
import { Link } from "react-router-dom";

import TitleFragment from "./TitleFragment";
import ScrollToTop from "./ScrollToTop";

function Vistor() {
    return (
        <section>
            <TitleFragment title="Hello Visitor, Wellcome to PthinkS" />

            <section className="landing_cards">
                <Link onClick={ScrollToTop} to="/visitor-guest" class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <h1 className="landing_cards_menu_title">Guest</h1>
                        </div>
                        <div class="flip-card-back">
                            <p className="landing_cards_menu_info">Click here if you are here as a guest!!!</p>
                        </div>
                    </div>
                </Link>

                <Link onClick={ScrollToTop} to="/visitor-meeting" class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <h1 className="landing_cards_menu_title">Meeting</h1>
                        </div>
                        <div class="flip-card-back">
                            <p className="landing_cards_menu_info">Click here if you are here for a meeting!!!</p>
                        </div>
                    </div>
                </Link>

                <Link onClick={ScrollToTop} to="/visitor-interview" class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <h1 className="landing_cards_menu_title">Interview</h1>
                        </div>
                        <div class="flip-card-back">
                            <p className="landing_cards_menu_info">Click here if you are here for an interview!!!</p>
                        </div>
                    </div>
                </Link>
            </section>
        </section>
    )
}

export default Vistor;