import React, { useState, useRef } from "react";

import TitleFragment from "./TitleFragment";

import "./styles/Visitor.css";
import "../App.css";
import VisitorForm from "./VisitorForm";

const VistorMeeting = ({ title }) => {

    return (
        <section>
            <TitleFragment title="Hello Visitor, Wellcome to PthinkS" />

            <VisitorForm title={title} />
        </section>
    );
};

export default VistorMeeting;