import linkedin from "../images/footer/linkedin.webp";
import facebook from "../images/footer/facebook.webp";
import youtube from "../images/footer/youtube.webp";
import twitter from "../images/footer/twitter-x.webp";
import insta from "../images/footer/instagram.webp";

const FooterSocialLinksData = [
    {
        href: "https://www.linkedin.com/company/pthinks/",
        src: linkedin,
        alt: "LinkedIn"
    },
    {
        href: "https://www.facebook.com/qtbangalore",
        src: facebook,
        alt: "Facebook"
    },
    {
        href: "https://www.youtube.com/@pthinks2359",
        src: youtube,
        alt: "YouTube"
    },
    {
        href: "https://x.com/pthinks_Qt",
        src: twitter,
        alt: "Twitter-X"
    },
    {
        href: "https://www.instagram.com/pthinks_family/",
        src: insta,
        alt: "Instagram"
    }
];

export default FooterSocialLinksData;