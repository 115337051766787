import './styles/TitleFragment.css';

const TitleFragmentImage = ({ title }) => {
    return (
        <section className="title_section">
            <h1 className="title modern_title">{title}</h1>
        </section>
    );
};

export default TitleFragmentImage;
