const VissionData = [
    {
        id: 1,
        text: "Lead globally in C++, Qt, and QML software development and innovation."
    },
    {
        id: 2,
        text: "Set new benchmarks with cutting-edge, high-impact software solutions."
    },
    {
        id: 3,
        text: "Inspire continuous learning and stay ahead of industry trends."
    },
    {
        id: 4,
        text: "Enable businesses to achieve extraordinary outcomes with our technology."
    },
    {
        id: 5,
        text: "Champion ethical practices and create a positive environmental impact."
    }
]

export default VissionData;