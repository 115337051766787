import React, { useState, useEffect } from 'react';
import './styles/AnouncementCard.css';

export function CardColumns() {
    const [announcementData, setAnnouncementData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    "https://api.jsonbin.io/v3/b/677d3e0bacd3cb34a8c58606/latest",
                    {
                        headers: {
                            "X-Master-Key": "$2a$10$g9HFN7jTfYswexcES.bBuOx0cARlZHwZ6eQHA6.JDZTbkAwi9CWXa",
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                console.log("API Response:", data); // Debugging: Log the API response
                setAnnouncementData(data.record.announcementData); // Update based on API structure
            } catch (err) {
                console.error("Error fetching announcement data:", err); // Log error for debugging
                setError(err.message || "An error occurred");
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures this runs only once on mount

    return (
        <div className="card-columns-container">
            {/* Announcement Section */}
            <div className="announcement-section">
                {error ? (
                    <p className="error-message">{error}</p>
                ) : announcementData ? (
                    <>
                        <h2 className="announcement-title">Exciting Upcoming Training Session</h2>
                        <p className="announcement-description">
                            Join us for an immersive training session where we explore the latest in Qt/QML.
                        </p>
                        <div className="announcement-details">
                            <div>
                                <h3 className="training-details">
                                    Training Date: <span>{announcementData.trainingDate}</span>
                                </h3>
                                <h3 className="training-details">
                                    Training Time: <span>{announcementData.trainingTime}</span>
                                </h3>
                                <h3 className="training-details">
                                    Venue: <span>{announcementData.venue}</span>
                                </h3>
                            </div>
                            <a href="/reach-us" className="register-btn">
                                Register Now
                            </a>
                        </div>
                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>

            {/* Cards Section */}
            <div className="card-columns">
                <Card
                    title="Qt Training "
              description={
    <ul>
        <li>Qt Fundamentals</li>
        <li>Qt Advance</li>
        <li>Qt3D.</li>
    </ul>
}
date={announcementData?.trainingDate || "Date to be announced"}
                    link="#"
                />
                <Card
                    title="QML Training"
                    description={
                        <ul>
                            <li>QML Essentials</li>
                            <li>QML Advance</li>
                            <li>QML3D</li>
                        </ul>
                    }
                    date={announcementData?.trainingDate || "Date to be announced"}
                    link="#"
                />
                <Card
                    title="Modern C++"
                    description={
                        <ul>
                            <li>Art
                                of
                                C++
                                Programming
                            </li>
                            <li>C++
                                11/14/17
                            </li>
                            <li>
                                Performance Analysis
                            </li>

                        </ul>
                    }
                    date={announcementData?.trainingDate || "Date to be announced"}
                    link="#"
                />
                <Card
                    title="Advanced Training"
                    description={
                        <ul>
                            <li>
                                Qt Internals
                            </li>
                            <li>
                                OpenGL
                            </li>
                            <li>
                                Unity3D
                            </li>

                        </ul>
                    }
                    date={announcementData?.trainingDate || "Date to be announced"}
                    link="#"
                />




            </div>
        </div>
    );
}

function Card({ title, description, date, link }) {
    return (
        <div className="card">
            <div className="card-overlay"></div>
            <div className="card-content">
                <h3 className="card-title">{title}</h3>
                <p className="card-description">{description}</p>
                <p className="card-date">{date}</p>
                <a href={link} className="card-btn">Learn More</a>
            </div>
        </div>
    );
}
