import React, { useState, useRef, useEffect } from "react";
import emailjs from '@emailjs/browser';

import TitleFragment from "./TitleFragment";
import CareersSkillsData from "./data/CareersSkillsData";
import VisitorInterviewBranch from "./data/VisitorInterviewBranchData";

import "./styles/Visitor.css"

const VisitorInterview = ({ title }) => {
    const form = useRef();
    const [type, setType] = useState('text');
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogType, setDialogType] = useState("");
    const [qualification, setQualification] = useState("");
    const [branch, setBranch] = useState("");
    const [skills, setSkills] = useState([]);
    const [experience, setExperience] = useState("");
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [filteredSkills, setFilteredSkills] = useState([]);
    const [govIdPlaceholder, setGovIdPlaceholder] = useState("Enter Your Aadhar Number *");
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);

    const updateFilteredRoles = (exp) => {
        const filtered = CareersSkillsData.filter(job => job.experience === exp);
        return filtered;
    };

    useEffect(() => {
        if (experience) {
            const roles = updateFilteredRoles(experience);
            setFilteredRoles(roles);
            if (roles.length > 0) {
                setFilteredSkills(roles[0].skills);
            } else {
                setFilteredSkills([]);
            }
        }
    }, [experience]);

    const handleGovIdChange = (e) => {
        const value = e.target.value;
        switch (value) {
            case "Not Selected":
                setGovIdPlaceholder("Select the Gov ID *");
                break;
            case "PAN":
                setGovIdPlaceholder("Enter PAN details *");
                break;
            case "Aadhar":
                setGovIdPlaceholder("Enter Aadhar details *");
                break;
            case "DL":
                setGovIdPlaceholder("Enter Driver's Lisence details *");
                break;
            case "Voter ID":
                setGovIdPlaceholder("Enter Voter ID details *");
                break;
            default:
                setGovIdPlaceholder("Select the Gov ID *");
                break;
        }
    };

    const showMessage = (message, type) => {
        setDialogMessage(message);
        setDialogType(type);
        setTimeout(() => {
            setDialogMessage("");
            setDialogType("");
        }, 4000);  // Adjusted time to show the dialog
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const govId = form.current.user_gov_type.value;
        if (!govId || govId === "Not Selected") {
            showMessage("Please select gov ID type.", "error")
            return;
        }

        if (!qualification || qualification === "Select") {
            showMessage("Please select your qualification.", "error");
            return;
        }

        const branch = form.current.user_branch.value;
        if (!branch || branch === "Not Selected") {
            showMessage("Please select your branch.", "error");
            return;
        }

        if (!experience || experience === "Select") {
            showMessage("Please select your years of experience.", "error");
            return;
        }

        if (skills.length === 0) {
            showMessage("Please select at least one skill.", "error");
            return;
        }

        // Check if the user is already registered
        const email = form.current.user_email.value;
        const Aadhar = form.current.user_gov.value;
        const phone = form.current.user_tel.value;
        if (isUserAlreadyRegistered(email,Aadhar)) {
            setIsAlreadyRegistered(true);
            setIsFormSubmitted(true);
            return;
        }

        emailjs
            .sendForm('service_d8nx4rl', 'template_77n4d27', form.current, {
                publicKey: 'Qj5RXAnOEEROxWVzm',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    form.current.reset();
                    showMessage("Email sent successfully!", "success");
                    setIsFormSubmitted(true); // Hide the form after successful submission
                    saveUserEmail(email); // Save the email to local storage
                    saveUserAadhar(Aadhar); // Save the Aadhar to local storage
                    saveUserPhone(phone); // Save the phone to local storage
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    showMessage("Email not sent. Please try again.", "error");
                },
            );
    };

    const isUserAlreadyRegistered = (email, aadhar, phone) => {
        const registeredEmails = JSON.parse(localStorage.getItem('registeredEmails')) || [];
        const registeredAadhars = JSON.parse(localStorage.getItem('registeredAadhars')) || [];
        const registeredPhones = JSON.parse(localStorage.getItem('registeredPhones')) || [];
        
        return registeredEmails.includes(email) || 
               registeredAadhars.includes(aadhar) ||
               registeredPhones.includes(phone);
    };

    const saveUserEmail = (email) => {
        const registeredEmails = JSON.parse(localStorage.getItem('registeredEmails')) || [];
        registeredEmails.push(email);
        localStorage.setItem('registeredEmails', JSON.stringify(registeredEmails));
    };

    const saveUserAadhar = (aadhar) => {
        const registeredAadhars = JSON.parse(localStorage.getItem('registeredAadhars')) || [];
        registeredAadhars.push(aadhar);
        localStorage.setItem('registeredAadhars', JSON.stringify(registeredAadhars));
    };

    const saveUserPhone = (phone) => {
        const registeredPhones = JSON.parse(localStorage.getItem('registeredPhones')) || [];
        registeredPhones.push(phone);
        localStorage.setItem('registeredPhones', JSON.stringify(registeredPhones));
    };

    const handleQualificationChange = (e) => {
        const value = e.target.value;
        setQualification(value);
        if (value === "MCA") {
            setBranch("MCA");
        } else {
            setBranch("");
        }
    };

    const handleSkillChange = (e) => {
        const value = e.target.value;
        setSkills((prevSkills) =>
            prevSkills.includes(value)
                ? prevSkills.filter((skill) => skill !== value)
                : [...prevSkills, value]
        );
    };

    const handleExperienceChange = (e) => {
        const value = e.target.value;
        setExperience(value);
    };

    return (
        <section>
            <TitleFragment title={title} />

            {isFormSubmitted ? (
                <div className="thank_you_message">
                    {isAlreadyRegistered ? "User is already registered!" : "Thank you for registering!"}
                </div>
            ) : (
                <div>
                    <div className="visitor_paragraph"><b>Welcome to the PthinkS journey...!</b><br />We're thrilled to explore your unique talents and how they can shape our innovative projects.<br />This interview is more than a formality - it's a chance to showcase your creativity and passion. Dive deep into the questions, and let your individuality shine. At PthinKS, we're not just looking for skills; we're looking for people who can inspire and innovate. Let's embark on this exciting path together and discover what amazing things we can create. Thank you for considering a future with us!</div>

                    <form className="visitor_form" ref={form} onSubmit={sendEmail}>
                        <div className="visitor_form_div">
                            <div className="visitor_form_div_sub">
                                <span className="visitor_form_span">
                                    <input className="visitor_form_input uppercase" type="text" placeholder="Full Name *" name="user_name" required />
                                </span>
                                <span className="visitor_form_span">
                                    <input className="visitor_form_input" type="email" placeholder="your.email@example.com *" name="user_email" required />
                                </span>
                            </div>
                            <div className="visitor_form_div_sub">
                                <span className="visitor_form_span">
                                    <input className="visitor_form_input" type="tel" placeholder="Mobile/Phone *" name="user_tel" required />
                                </span>
                                <span className="visitor_form_span">
                                    <input className="visitor_form_input" type={type} name="user_date" onFocus={() => setType('date')} onBlur={() => setType('text')} placeholder="DD-MM-YYYY : Date of visit *" required />
                                </span>
                            </div>
                            <div className="visitor_form_div_sub">
                                <span className="visitor_form_span">
                                    <select className="visitor_form_input" name="user_gov_type" onChange={handleGovIdChange} required>
                                    <option value="Not Selected">Select Gov ID</option>
                                        <option value="Aadhar">Aadhar</option>
                                    </select>
                                </span>
                                <span className="visitor_form_span">
                                    <input className="visitor_form_input" type="text" name="user_gov" placeholder={govIdPlaceholder} required />
                                </span>
                            </div>
                            <div className="visitor_form_div_sub">
                                <span className="visitor_form_span">
                                    <input className="visitor_form_input" type="text" name="user_year" placeholder="Year of passing *" required />
                                </span>
                                <span className="visitor_form_span">
                                    <input className="visitor_form_input" type="text" name="user_location" placeholder="Your location (Native) *" required />
                                </span>
                            </div>
                            <div className="visitor_form_div_sub">
                                <span className="visitor_form_span">
                                    <label className="visitor_form_label">Qualification : </label>
                                    <select className="visitor_form_input" name="user_qualification" onChange={handleQualificationChange} required>
                                        <option value="Not selected">Select</option>
                                        <option value="B.E./Btech">BE / BTech</option>
                                        <option value="Mtech/MS">MTech / MS</option>
                                        <option value="MCA">MCA</option>
                                    </select>
                                </span>
                                <span className="visitor_form_span">
                                    <label className="visitor_form_label">Branch : </label>
                                    <select className="visitor_form_input" name="user_branch" value={branch} onChange={(e) => setBranch(e.target.value)} required>
                                        {VisitorInterviewBranch.map((branch) => (
                                            <option value={branch.value} key={branch.value}>{branch.key}</option>
                                        ))}
                                    </select>
                                </span>
                            </div>
                            <div className="visitor_form_div_sub">
                                <span className="visitor_form_span">
                                    <label className="visitor_form_label">Years of experience : </label>
                                    <select className="visitor_form_input" name="user_exp" onChange={handleExperienceChange} required>
                                        {CareersSkillsData.map((exp) => (
                                            <option value={exp.experience} key={exp.experience}>{exp.experience}</option>
                                        ))}
                                    </select>
                                </span>
                                <span className="visitor_form_span">
                                    <label className="visitor_form_label">Available role : </label>
                                    <select className="visitor_form_input" name="user_role" required>
                                        {filteredRoles.map((role) => (
                                            <option value={role.title} key={role.title}>{role.title}</option>
                                        ))}
                                    </select>
                                </span>
                            </div>
                            <span className="visitor_form_span visitor_form_span_big">
                                <label className="visitor_form_label">Skills : </label>
                                <div className="visitor_form_skills">
                                    {filteredSkills.map((skill) => (
                                        <label key={skill} className="visitor_form_skill_check">
                                            <input type="checkbox" name="user_skills" value={skill} onChange={handleSkillChange}
                                                disabled={
                                                    (experience === "Fresher" && skills.length >= 2 && !skills.includes(skill)) ||
                                                    (experience === "0.6 to 1 year" && skills.length >= 3 && !skills.includes(skill))
                                                }
                                            />&nbsp;{skill}
                                        </label>
                                    ))}
                                </div>
                            </span>
                            <span className="visitor_form_span visitor_form_span_big">
                                <input className="visitor_form_submit" type="submit" value="Send" />
                            </span>
                            {dialogMessage && (
                                <div className="visitor_paragraph2">We wish you all the best for your future endeavors!</div>
                            )}
                        </div>
                    </form>
                </div>
            )}

            {dialogMessage && (
                <div className={`dialog ${dialogType}`}>
                    {dialogMessage}
                </div>
            )}
        </section>
    );
};

export default VisitorInterview;