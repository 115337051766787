import pplIcon from '../images/landing/group.webp';
import teachIcon from '../images/landing/online-counseling.webp';
import classIcon from '../images/landing/classroom.webp';

const LandingCardsData = [
    {
        title: 'Qt Automotive',
        info: 'Powering Next-Generation In-Vehicle Experiences with expert services for future automotive technology.',
        desc:[' Software for Electronic Control Units (ECUs)','Hardware Integration and Electronics','Interactive dashboard and touch screen '],
    },
    {
        title: 'HMI Development',
        info: 'Expert Qt team delivering seamless human-machine interfaces for Automotive, Health-Care, Smart devices with compliant UI software.',
        desc:['Advanced Visualization for Healthcare Systems ','Seamless Integration for Smart Connected Devices ','Efficiency and Scalability for Industrial Automation '],
    },
    {
        title: 'Qt & QML Architecture',
        info: 'Your one-stop service for robust, high-performance cross-platform architectures using Qt’s C++ backend and QML’s intuitive.',
        desc:['Dynamic UI Development',' Backend Integration with C++','Real-Time Responsiveness'],
        
    },
    {
        title: 'Code Review',
        info: 'Optimize your C++ Qt/QML codebase with expert code recommendations and reviews for improved performance and efficiency.',
        desc:['Code Quality and Readability','Performance Optimization','Error Handling and Robustness'],
        
    },
    {
        title: 'Performance Analysis',
        info: 'Comprehensive performance analysis to identify bottlenecks and optimize your Qt/QML application for speed and efficiency.',
        desc:['Static Code Review','Memory Measurement','Processor Speed and Utilization'],
    },
    {
        title: 'C++ Qt/QML Training',
        info: 'Led by Qt Champion Dheerendra V Purohit, our sessions offer 80% hands-on to equip with the advanced knowledge and skills  to create professional projects.',
        desc:['Modern C++','Qt/QML - Advance','Qt/QML - 3D & OpenGL','Performance Analysis'],
    }
];

export default LandingCardsData;
