// JobBoard.jsx
import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./styles/ExpandableCard.css";

const WhatsAppIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24"
    className="whatsapp-icon"
  >
    <path href="https://chat.whatsapp.com/I8NnmXCpoDk6xtnZCA6hnQ"/>
  </svg>
);

const jobs = [
  {
    title: "Jr. Associate Software Developer",
    AvailablePosition: "10",
    Experience: "0-1 years",
    type: "Full-time",
    description: 
`About the Role:
  We are looking for a Fresher with a strong interest in software development to join our dynamic team. 


Key Responsibilities:
• Assist in Development & Design 
• Collaborate on Project Tasks 
• Learn and Apply Best Practices 
• Explore Technical Innovations 


Requirements:
• Education: Bachelor's degree in CSE/ISE/ECE. 
• Basic knowledge of C/ C++.  
• Fundamentals OOPS Concepts.
`,
  },
  {
    title: "Associate Software Developer - C++",
    AvailablePosition: "10",
    Experience: "1-3 years",
    type: "Full-time",
    description: `About the Role:
    We are looking for enthusiastic Developers with a passion for Qt/QML to join our team. You will be involved in designing and developing user interfaces and applications using Qt/QML, collaborating with experienced developers, and gaining hands-on experience in software development.


Key Responsibilities:
• Create user flows and wireframes
• Conduct user research and testing
• Design intuitive interfaces
• Collaborate with development team

Requirements:
• Education: Bachelor's degree in CSE/ISE/ECE. 
• Basic knowledge of C/ C++.  
• Fundamentals OOPS Concepts & Data Structures.
• Basic  Qt/QML


`,
  },
  {
    "title": "Sr. Software Developer - Qt/QML",
    "AvailablePosition": "8",
    "Experience": "3-5 years",
    "type": "Full-time",
    "description": 
    `About the Role:
      We are seeking a seasoned Senior Software Developer specializing in Qt/QML to join our team. In this role, you will design and implement complex user interfaces and applications, mentor junior developers, and contribute to the architecture and optimization of our software solutions.
  
  Key Responsibilities:
  • Lead Development & Implementation 
  • Mentor Junior Team Members 
  • Contribute to Architecture & Design 
  • Drive Technical Innovation 
  
  Requirements:
  • Education: Bachelor's/Master's degree in Computer Science, Engineering, or a related field. 
  • Strong expertise in Qt Framework, QML, and C++. 
  • Proven experience integrating QML with C++ backends. 
  • Proficiency in Qt Quick Controls, animations, and UI performance optimization. 
  • Experience with agile methodologies and version control systems. 
  • Excellent problem-solving and communication skills.`
  }
  ,
  {
    "title": "Tech Lead - Qt/QML",
    "AvailablePosition": "6",
    "Experience": "6-8 years",
    "type": "Full-time",
    "description": 
    `About the Role:
      We are looking for a highly skilled Tech Lead specializing in Qt/QML to lead and manage a team of developers. You will oversee the design, development, and delivery of advanced user interfaces and applications, ensuring high standards of technical excellence.
  
  Key Responsibilities:
  • Lead and manage a development team. 
  • Drive the design and implementation of Qt/QML solutions. 
  • Collaborate on project planning and technical roadmaps. 
  • Ensure code quality and best practices. 
  
  Requirements:
  • Education: Bachelor's/Master's degree in Computer Science, Engineering, or related field. 
  • Extensive experience with Qt Framework, QML, and Modern C++ . 
  • Proven ability to integrate QML with C++ backends. 
  • Strong knowledge of UI performance optimization and animations. 
  • Leadership experience in managing development teams. 
  • Excellent problem-solving and communication skills.`
  }
  ,
  {
    "title": "Associate Software Developer - Python",
    "AvailablePosition": "2",
    "Experience": "3-5 years",
    "type": "Full-time",
    "description": 
    `About the Role:
      We are seeking a seasoned Senior Software Developer specializing in Qt/QML to join our team. In this role, you will design and implement complex user interfaces and applications, mentor junior developers, and contribute to the architecture and optimization of our software solutions.
  
  Key Responsibilities:
  • Lead Development & Implementation 
  • Mentor Junior Team Members 
  • Contribute to Architecture & Design 
  • Drive Technical Innovation 
  
  Requirements:
  • Education: Bachelor's/Master's degree in CSE,ISE,ECE. 
  • Strong expertise in Python  and C++. 
  • Proven experience integrating in Projects. 
  • Proficiency in Python Quick Controls, animations, and UI performance optimization. 
  • Experience with agile methodologies and version control systems. 
  • Excellent problem-solving and communication skills.`
  },
  {
    
      "title": "Qt/QML Architect",
      "AvailablePosition": "2",
      "Experience": "12+ years",
      "type": "Full-time",
      "description": 
      `About the Role:
        We are seeking an experienced Qt/QML Architect to design and define the overall architecture of our Qt-based applications. You will work closely with stakeholders, guide the technical direction of projects, and ensure scalable, efficient, and maintainable solutions.
    
    Key Responsibilities:
    • Define and oversee Qt/QML application architecture. 
    • Collaborate with stakeholders to understand and address technical requirements. 
    • Develop reusable frameworks and design patterns. 
    • Mentor and guide development teams. 
    • Stay updated on emerging technologies and Qt best practices.
    
    Requirements:
    • Education: Bachelor's/Master's degree in Computer Science, Engineering, or related field. 
    • Deep expertise in Qt Framework, QML, and C++. 
    • Extensive experience in architecture design and scalability. 
    • Proficient in Qt Quick Controls, performance optimization, and animations. 
    • Strong understanding of software design principles and patterns. 
    • Excellent communication and technical leadership skills.`
    },
    
    

  
  // Add more job listings as needed
];

export function JobBoard() {
  const [active, setActive] = useState(null);
  const ref = useRef(null);
  const id = useId();

  useEffect(() => {
    function onKeyDown(event) {
      if (event.key === "Escape") {
        setActive(false);
      }
    }

    if (active && typeof active === "object") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [active]);

  const handleWhatsAppClick = (number, title) => {
    const message = `Hi, I'm interested in the ${title} position.`;
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://chat.whatsapp.com/I8NnmXCpoDk6xtnZCA6hnQ`, '_blank');
  };

  return (
    <>
      <AnimatePresence>
        {active && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="overlay"
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {active && (
          <div className="modal-container">
            <motion.div
              layoutId={`card-${active.title}-${id}`}
              ref={ref}
              className="modal-content"
            >
              <div className="modal-header">
                <motion.h2 layoutId={`title-${active.title}-${id}`} className="modal-title">
                  {active.title}
                </motion.h2>
                <button 
                  className="modal-close"
                  onClick={() => setActive(null)}
                >
                  ×
                </button>
              </div>
              
              <div className="modal-details">
                <div className="job-meta">
                  <p className="company">{active.company}</p>
                  <p className="location">{active.location}</p>
                  <p className="type">{active.type}</p>
                </div>
                
                <div className="job-description">
                  <pre>{active.description}</pre>
                </div>

                <button 
                  className="whatsapp-button"
                  onClick={() => handleWhatsAppClick(active.whatsappNumber, active.title)}
                >
                  <WhatsAppIcon /> Apply Now
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <div className="jobs-container">
        {/* <h1 className="jobs-title">Open Positions</h1> */}
        {jobs.map((job) => (
          <motion.div
            layoutId={`card-${job.title}-${id}`}
            key={`card-${job.title}-${id}`}
            onClick={() => setActive(job)}
            className="job-card"
          >
            <div className="job-card-content">
              <motion.h3 layoutId={`title-${job.title}-${id}`} className="job-card-title">
                {job.title}
              </motion.h3>
              <div className="job-card-meta">
                <span className="company">Avil Pos: {job.AvailablePosition}</span>
                <span className="company">Exp: {job.Experience}</span>
                <span className="type">{job.type}</span>
              </div>
            </div>
            <button className="view-details-button">
              View Details
            </button>
          </motion.div>
        ))}
      </div>
    </>
  );
}