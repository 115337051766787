import React, { useState } from 'react';
import './styles/spotlight.css';

const BasicSpotlight = ({ children, className = '', containerClassName = '' }) => {
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setMousePos({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        });
    };

    return (
        <div
            className={`spotlight-container ${containerClassName}`}
            onMouseMove={handleMouseMove}
        // style={{ height: '70svh' }}
        >
            {/* Background dot pattern */}
            <div className="dot-pattern" />

            {/* Spotlight effect */}
            <div
                className="spotlight-effect"
                style={{
                    maskImage: `radial-gradient(
                        200px circle at ${mousePos.x}px ${mousePos.y}px,
                        black 0%,
                        transparent 100%
                    )`,
                    WebkitMaskImage: `radial-gradient(
                        200px circle at ${mousePos.x}px ${mousePos.y}px,
                        black 0%,
                        transparent 100%
                    )`
                }}
            >
                {/* Spotlight center dot */}
                <div
                    className="spotlight-dot"
                    style={{
                        left: `${mousePos.x}px`,
                        top: `${mousePos.y}px`
                    }}
                />
            </div>

            {/* Content */}
            <div className={`content ${className}`}>
                {children}
            </div>
        </div>
    );
};

// Optional Highlight component
const Highlight = ({ children, className = '' }) => {
    return (
        <span className={`highlight ${className}`}>
            {children}
        </span>
    );
};

export { BasicSpotlight, Highlight };