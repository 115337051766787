import nokiaQtSoec from "../images/landing/NokiaQtCertifiedSpecialistLogo_lrg.webp";
import nokiaQtDev from "../images/landing/Nokia_Certified_Qt_Developer_Logo-4.webp";
import QtChamp from "../images/landing/Qt_Champion_200-4.webp";

const LandingQTImagesData = [
    {
        src: nokiaQtSoec,
        alt: 'Nokia certified image',
    },
    {
        src: QtChamp,
        alt: 'Qt Champion image',
    },
    {
        src: nokiaQtDev,
        alt: 'Nokia certified image',
    },
];

export default LandingQTImagesData;