import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';

import Header from './components/Header';
import LandingPage from './components/LandingPage';
import AboutUs from './components/AboutUs';
import ReachUs from './components/ReachUs';
import Careers from './components/Careers';
import QtTraining from './components/QtTraining';
import QtConsulting from './components/QtConsulting';
import QtCoaching from './components/QtCoaching';
import MoreCustomers from './components/MoreCustomers';
import Footer from './components/Footer';
import Team from './components/Team';
import ErrorPage from './components/ErrorPage';
import Vistor from './components/Visitor';
import VistorMeeting from './components/VisitorMeeting';
import VisitorInterview from './components/VisitorInterview';
import Login from './components/VisitorLogin'; // Import your Login component
import PrivateRoute from './components/PrivateRoute'; // Import the PrivateRoute component
import { UpdateAnnouncementForm } from './components/AnnouncementEdit';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/reach-us' element={<ReachUs />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/qt-training' element={<QtTraining />} />
          <Route path='/qt-consulting' element={<QtConsulting />} />
          <Route path='/qt-coaching' element={<QtCoaching />} />
          <Route path='/more-customers' element={<MoreCustomers />} />
          <Route path='/team' element={<Team />} />
          <Route path='/login' element={<Login />} />
          <Route path='/visitor' element={<Vistor/>}  />
          <Route path='/visitor-guest' element={<VistorMeeting/>} />
          <Route path='/visitor-meeting' element={<VistorMeeting/>} />
          <Route path='/visitor-interview' element={<PrivateRoute element={VisitorInterview} title="Interview form" />} />
          <Route path='/announce-edit' element={<UpdateAnnouncementForm />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;